<template>
  <div class="row">
    <div class="col-12">
      <h2>Em breve...</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComingSoon'
}
</script>

<style scoped></style>
